<template>
  <div class="my-1 mx-2 flexb fjustify-start falign-center" v-if="ucObj != null" style="width: 90%">
    <div class="px-1" style="">
      <i v-i:duo#graduation-cap#22></i>
    </div>
    <div class="fs-10pt me-3 f-raleway px-1 item-truncated pt-1">
      <div class="">
        <v-tooltip bottom content-class="px-1 py-0 f-sanspro fw-600" open-delay="1200">
          <template v-slot:activator="{ on, attrs }">
            <v-card v-c:I color="transparent" flat class="fw-600 text-truncate" v-bind="attrs" v-on="on" @click="$emit('ucClick',uc)">
              <span class="fs-7pt fw-900" v-c:P>UC</span>
              {{ucObj.nome}}
            </v-card>
          </template>
          <span class="fs-8pt">{{ucObj.nome}}</span>
        </v-tooltip>
        <div class="w180" v-if="itens != null && metas != null">
          <span class="me-1 f-roboto fw-600">{{Object.keys(itens).length}}</span>
          <span class="me-1 f-roboto fw-200" v-if="Object.keys(itens).length > 1">itens em</span>
          <span class="me-1 f-roboto fw-200" v-else>item em</span>
          <span class="me-1 f-roboto fw-600">{{Object.keys(metas).length}}</span>
          <span class="me-1 f-roboto fw-200">metas de compreensão</span>
        </div>
      </div>
    </div>
    <div class="m-0 mt-n1 p-0" style="" :key="refresh">
      <span v-for="(area, key, index) in ucObj.areas" :key="key">
        <tagarea class="me-1" :sigla="area.sigla"/>
      </span>
    </div>
    <div class="w26 px-1" v-if="crud">
      <editobject :obj="ucObj" @onSave="onSave"/>
    </div>
    <div class="px-1" v-if="crud">
      <v-btn color="primary" icon small>
        <i v-i:duo#trash#16 v-c:D class="mt-n1 me-1"></i>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import tagarea from "@/views/cursos/tagArea.vue";
import editobject from "./edit-object.vue"

export default {
  name: "ucitem",
  components: { tagarea, editobject },
  props: {
    crud: { default: false, type: Boolean },
    uc: { default: "xxxx", type: String },
    area: { default: null, type: Object },
    encomendas: { default: null, type: Object }
  },
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      ucObj: null,
      metas: {},
      itens: {},
    }
  },
  watch: {
    uc() {
      this.build();
    },
    encomendas() {
      this.build();
    }
  },
  mounted: function() {
    var self = this;
    self.build();
  },
  methods:{

    build() {
      var self = this;
      rdb.ref('/curriculo/ucs/'+this.uc).on('value',function(snapshot) {
        self.ucObj = snapshot.val();
        //console.log("self.ucObj",self.ucObj);
        self.refresh++;
      });

      this.metas = {};
      this.itens = {};
      for(var key in this.encomendas) {
        if(this.encomendas[key].ucID == this.uc) {
          this.itens[key] = this.encomendas[key];
          this.metas[this.encomendas[key].meta] = this.encomendas[key];
        }
      }
    },

    onSave(obj) {
      this.$emit('onSave',obj);
    }
  }
}
</script>

<style scoped>

</style>
