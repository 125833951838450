<template>
  <div class="">
    <!-- TABS -->
    <v-tabs v-bg:B class="mt-0 px-2 pt-2 pb-1 rounded" height="22" v-model="tab">
      <v-tab class="mt-0 px-2 me-1" v-for="item in items" :key="item" style="min-width: 0px;">
        <template slot:default class="px-0" >
          <span class="text-nonecase f-raleway fs-10pt fw-600">{{ item }}</span>
        </template>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" v-bg:b>
      <v-tab-item>
        <encomendas />
      </v-tab-item>

      <!-- TODAS UCS -->
      <v-tab-item>
        <v-card outlined class="p-1 pb-0 pe-2 mt-2">
          <div class="row pb-0">
            <!-- LISTA DE UCS -->
            <div class="col-12 col-lg-3 mb-3 mt-1 pb-1">
              <v-card outlined class="pb-0 mb-0">
                <v-card-text class="text-left text-justify pb-2">
                  <p :style="" class="ms-1 mb-0 f-roboto fs-10pt fw-300 text-left">
                    Minhas Unidades Curriculares
                  </p>
                  <v-card outlined class="mt-2 pt-1 pb-0 mb-2">
                    <ucsview @selectInitialUc="selectInitialUc" @clickUc="clickUc"/>
                  </v-card>
                </v-card-text>
              </v-card>
            </div>
            <!-- PLANO DE ENSINO -->
            <div class="col-12 col-lg-9">
              <planocontainer v-if="uc != null" :ucSel="uc" :key="refresh"/>
            </div>
          </div>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card outlined class="p-2 mt-2" v-if="false">
          <v-btn v-if="true" color="primary" outlined small @click="falar">
            falar
          </v-btn>
        </v-card>
      </v-tab-item>

      <v-tabs-item>
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <bannerinscricao />
            </v-col>
          </v-row>
        </v-container>
      </v-tabs-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js'
import ucsview from "./ucsView.vue"
import planocontainer from "./planoContainer.vue"
import { speech } from "@/components/speech/speech.js"
import encomendas from "./encomendas/encomendas.vue"
import bannerinscricao from "@/views/live/inscricaoUCD/banner-inscricao.vue"

export default {
  name: 'minhasucs',
  components: { ucsview, planocontainer, encomendas, bannerinscricao },
  data(){
    return {
      ofertas: {},
      ucs: {},
      tab: null,
      items: [ 'Encomendas A2 - 2021/2', 'Minhas UCs 2021/2', 'Inscrição seleção docente - UCs Live 2022/1' ],
      uc: null,
      refresh: 0,
    }
  },
  computed: {
  },
  created() {
    this.log_s('Created');
  },
  mounted() {
    var self = this;
    this.log_s('Mounted');


  },
  methods: {

    selectInitialUc(uc) {
      //console.log("selectInitialUc");
      //console.log(uc);
      this.uc = uc;
      this.refresh++;
    },

    clickUc(uc) {
      //console.log("clickUc");
      //console.log("uc",uc);
      this.uc = uc;
      this.refresh++;
    },

    falar() {
      console.log("falar");
      speech.init();
      speech.play('Boa tarde! <break time="1000"/>Tudo bem com você?');
    }

  }
}
</script>

<style scoped>


</style>
