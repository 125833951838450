<template>
  <div class="">
    <v-row class="mt0" v-bg:b>
      <v-col>
        <v-list-item three-line>
          <v-avatar size="90">
            <img alt="user" :src="userLog.urlFoto"/>
          </v-avatar>
          <v-list-item-content class="align-self-center mx-4 py-3 px-3 rounded-lg">
            <v-row>
              <v-col>
                <div class="mb-1 f-lato fw-700 fs-12pt" v-c:K>
                  {{userLog.nome}}
                  <duoicon icon="brightness" class="fs-13pt m-0 p-0" :color="cor.active.K"/>
                </div>
                <div class="mb-1 p-0 fs-11pt f-roboto fw-300" v-c:I>
                  {{userLog.email}}
                </div>
                <div v-c:K class="fs-11pt f-raleway fw-300">
                  {{userLog.cargo}} - {{userLog.ies}} - {{userLog.regional}}
                </div>
              </v-col>
              <v-col class="d-none d-sm-block">
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="12">
        <minhasucs />
      </v-col>

    </v-row>

  </div>
</template>

<script>
import fakepost from "@/components/dummy/fakePost.vue"
import minhasucs from "./minhasucs.vue"

export default {
  name: 'profile',
  components: { fakepost, minhasucs },
  data(){
    return {
      rating: 4,
      checking: false,
    }
  },
  computed: {
  },
  created() {
    this.log_s('Created');
  },
  mounted() {
    this.log_s('Mounted');
  },
  methods: {

  }
}
</script>

<style scoped>


</style>
