<template>
  <v-row class="m-0 p-0">
    <v-col cols="12" class="m-0 p-0">
      <v-card class="p-1 mb-0">
        <!-- <ucitem class="mb-3" :uc="uc" :encomendas="encomendas" :key="uc+refresh"/> -->
        <v-card class="p-1 mx-2 my-2 mb-4" outlined elevation="2">
          <div class="m-0 p-0 ms-1 line-height-2">
            <span class="me-1 f-roboto fs-8pt fw-500" v-c:P>Nome Banco:</span>
            <span class="me-1 f-roboto fs-10pt fw-500" v-c:I>{{nomeBanco}}</span>
          </div>
          <div class="m-0 p-0 line-height-2 ms-1">
            <span class="me-2 f-roboto fs-8pt fw-500" v-c:P>ID Banco:</span>
            <span class="me-1 f-roboto fs-10pt fw-500" v-c:I>{{idBanco}}</span>
          </div>
        </v-card>
        <div v-for="(meta,key,index) in metas" :key="key+refresh" class="my-1 mb-3 mx-0 flexb falign-center" style="justify-content: space-between;">
          <v-card class="p-1 mx-2 flex1" :color="hexTints(cor.active.b,0.6)">
            <readmore class="text-dark mt-0 fs-9pt f-roboto fw-300" size="120" :text="meta.texto" />
            <div class="text-right m-0 mt-n2 p-0 h22">
              <btnverdetalhesmeta :meta="meta" />
            </div>
          </v-card>
          <v-card class="p-1 pb-0 text-center mt-0" outlined elevation="0">
            <p class="m-0 p-0 f-roboto line-height-2 fw-400 fs-7pt">Qtde Itens</p>
            <p class="m-0 p-0 f-roboto line-height-4 fs-9pt fw-600">{{itens[meta.id]}}</p>
          </v-card>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import ucitem from "./uc-item.vue";
import readmore from "@/components/readmore.vue"
import btnverdetalhesmeta from "./btnVerDetalhesMeta.vue";

export default {
  name: "encomendadetalhes",
  components: { ucitem, readmore, btnverdetalhesmeta },
  props: {
    uc: { default: "xxxx", type: String },
    area: { default: null, type: Object },
    encomendas: { default: null, type: Object }
  },
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      metas: {},
      itens: {},
      nomeBanco: "",
      idBanco: "",
    }
  },
  watch: {
    uc() {
      this.build();
    },
    encomendas() {
      this.build();
    }
  },
  mounted: function() {
    var self = this;
    this.build();
  },
  methods:{

    build() {
      var self = this;
      //console.log("encomendas",this.encomendas);
      //console.log("this.uc",this.uc);
      this.metas = {};
      this.itens = {};
      for(var key in this.encomendas) {
        if(this.encomendas[key].ucID == this.uc) {
          self.nomeBanco = this.encomendas[key].nomeBanco;
          self.idBanco = this.encomendas[key].idBanco;
          if(this.itens[this.encomendas[key].meta] == undefined) {
            this.itens[this.encomendas[key].meta] = 0;
          }
          this.itens[this.encomendas[key].meta]++;
          rdb.ref('/curriculo/metas/'+this.encomendas[key].meta).once('value',function(snapshot) {
            var meta = snapshot.val();
            //console.log("meta",meta);
            self.metas[meta.id] = meta;
            self.refresh++;
            self.$forceUpdate();
          });
        }
      }
    },

  }
}
</script>

<style scoped>
</style>
