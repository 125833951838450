<template>
  <v-card class="p-1">
    <div class="my-1 mx-0 flexb falign-center" style="justify-content: space-between;">
      <div class="mx-auto mt-2">
        <svgreceipt size="w100 ms-5" />
      </div>
      <div class="flex1 ms-2">
        <v-card class="px-2 py-1 pt-4 mx-2 h100" :color="hexTints(cor.active.b,.6)" v-c:I flat >
          <p class="my-0 mb-n1 p-0 text-center mt-2 line-height-1">
            <span class="ms-1 fs-15pt f-roboto fw-600">AVALIAÇÃO A1 - 2021/2</span>
          </p>
          <p class="mt-0 mb-1 p-0 text-center mt-3 line-height-1 fs-9pt f-raleway fw-500">
            <span class="fs-9pt f-roboto fw-600">ENCOMENDA:</span>
            Elaboração de itens de avaliação
          </p>
        </v-card>
      </div>
    </div>

    <hr class="my-1 mt-3"/>

    <v-card class="p-1" v-if="false">
      <v-alert type="info" :color="cor.active.S">
        <p class="m-0 p-0 fs-10pt">Encomendas serão disponibilizadas em breve...</p>
      </v-alert>
    </v-card>

    <v-card class="my-2 mb-3 p-0 pb-1 mx-2" v-for="(uc,key,index) in ucs" :key="key+key" v-bg:b>
      <ucitem :uc="uc[0].uc" :key="uc[0].uc+refresh"/>
      <v-card class="p-1 mx-2 my-2 mb-2" outlined elevation="2" v-for="(encomenda,index) in uc" :key="index">
        <div class="m-0 p-0 line-height-2 ms-1">
          <span class="me-2 f-roboto fs-8pt fw-500" v-c:P>ID Banco:</span>
          <span class="me-6 f-roboto fs-10pt fw-500" v-c:I>{{encomenda.encomenda.idBanco}}</span>
          <span class="me-2 f-roboto fs-8pt fw-500" v-c:P>TURMA:</span>
          <span class="me-1 f-roboto fs-10pt fw-500" v-c:I>{{encomenda.encomenda.turma}}</span>
        </div>
        <div class="m-0 p-0 ms-1 line-height-2">
          <p class="p-0 m-0 mt-1 f-roboto fs-8pt fw-500" v-c:P>Nome Banco:</p>
          <span class="me-1 f-roboto fs-9pt fw-500" v-c:I>{{encomenda.encomenda.nomeBanco}}</span>
        </div>
      </v-card>
    </v-card>

  </v-card>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import svgreceipt from "@/components/svgs/svg-receipt.vue";
import ucitem from "./uc-item-a1.vue";
import encomendasdetalhes from "./encomenda-detalhes.vue";

export default {
  name: "cardencomendasa2",
  components: { svgreceipt, ucitem, encomendasdetalhes },
  props: [],
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      encomendas: {},
      ucs: {},
      metas: {},
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    self.loading = true;
    rdb.ref('/curriculo/avaliacao/2021S2/encomendasA1').orderByChild("profID").equalTo(this.userLog.id).on('value',function(snapshot) {
      self.encomendas = snapshot.val();
      console.log("self.encomendas",self.encomendas);
      self.ucs = {};
      self.metas = {};
      for(var key in self.encomendas) {
        if(self.ucs[self.encomendas[key].ucID] == undefined) {
          self.ucs[self.encomendas[key].ucID] = [];
        }
        self.ucs[self.encomendas[key].ucID].push({
          uc: self.encomendas[key].ucID,
          encomenda: self.encomendas[key]
        });
      }
      self.refresh++;
      console.log("self.ucs",self.ucs);
      //console.log("self.metas",self.metas);
    });

  },
  methods:{

    build() {
      var self = this;
    }
  }
}
</script>

<style scoped>
</style>
