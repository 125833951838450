<template>
  <v-card>
      <v-img height="" src="https://via.placeholder.com/150x50"></v-img>
      <v-card-title class="text-left">
        <dummy attr="lorem.sentence"/>
      </v-card-title>
      <v-card-text class="text-left text-justify">
        <dummy attr="lorem.paragraphs"/>
      </v-card-text>
      <v-divider class="mx-4"></v-divider>
      <v-card-text  class="text-left text-justify">
        <dummy attr="lorem.paragraph"/>
      </v-card-text>
      <v-card-actions>
        <v-btn color="">Button</v-btn>
      </v-card-actions>
    </v-card>
</template>

<script>
import { faker } from '@/components/dummy/faker.js'

export default {
  name: 'fakepost',
  components: { },
  computed: {
  },
  data() {
    return {
      texto: "dummy"
    }
  },
  mounted() {
    console.log("fakepost Mounted");
  },
  methods: {
  }
}
</script>
