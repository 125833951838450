<template>
  <v-row class="mt-0">
    <v-col cols=12>
      <v-container fluid>
        <v-row justify="space-between" align="center">
          <v-col cols="12" sm="12" md="4">
            <v-card class="p-3 pt-2 m-0" outlined elevation="2">
              <p class="m-0 p-0 f-raleway fs-14pt fw-400 text-nonecase">Minhas encomendas</p>
              <hr class="my-1 mb-4">
              <p class="m-0 my-2 p-0 f-raleway fs-11pt fw-300 text-nonecase">
                Olá, professor!
              </p>
              <p class="m-0 my-2 p-0 f-raleway fs-11pt fw-300 text-nonecase text-justify">
                Neste semestre, sua encomenda de itens avaliativos da A2,
                assim como os endereços para cadastro das avaliações A1 de suas turmas,
                estão disponíveis aqui, no <span class="f-sanspro fs-11pt fw-600">App Integra</span>.
              </p>
              <p class="m-0 my-2 p-0 f-raleway fs-11pt fw-300 text-nonecase text-justify">
                Acesse a formação destinada a você na plataforma <span class="f-sanspro fs-11pt fw-600">ECOA</span>.
                Procure pela <span class="f-sanspro fs-11pt fw-600">FORMAÇÃO E2A</span>.
              </p>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="8">
            <v-img src="https://firebasestorage.googleapis.com/v0/b/appintegra-d8424.appspot.com/o/appintegra%2Fformacao.png?alt=media&token=0c646528-95ec-4778-96f3-f2c6327fb63e"></v-img>
          </v-col>
        </v-row>
      </v-container>
    </v-col>
    <v-col cols="12" sm="12" md="6">
      <cardencomendasa2 />
    </v-col>
    <v-col cols="12" sm="12" md="6">
      <cardencomendasa1 />
    </v-col>
  </v-row>
</template>

<script>
import { rdb, snapshotValToArray, generatePushID } from '@/firebasedb.js';
import cardencomendasa1 from "./card-encomendas-a1.vue";
import cardencomendasa2 from "./card-encomendas-a2.vue";
import moment from "moment";

export default {
  name: "encomendas",
  components: { cardencomendasa1, cardencomendasa2 },
  props: [],
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      encomendas: {},
      ucs: {},
      metas: {},
      lastView: null,
    }
  },
  watch: {},
  mounted: function() {
    var self = this;

    rdb.ref("/curriculo/avaliacao/2021S2/profsCientes/"+self.userLog.id).once("value",function(snapshot) {
      var value = snapshot.val();
      if(value == null || value.id == undefined) {
        var updates = {};
        updates["/curriculo/avaliacao/2021S2/profsCientes/"+self.userLog.id] = {
          id: self.userLog.id,
          createdAt: moment().format(),
          nome: self.userLog.nome,
        };
        //console.log("updates",updates);
        rdb.ref().update(updates);
        localStorage.setItem('encomendaLastView', moment().format());
      }
    });

    var lastView = localStorage.getItem('encomendaLastView');
    if(lastView != null) {
      var duration = moment.duration(moment().diff(moment(lastView))).asMinutes();
      console.log("duration",duration);
      if(duration > 10) {
        var updates = {};
        updates["/curriculo/avaliacao/2021S2/profsCientes/"+self.userLog.id+"/logs/"+generatePushID()()] = moment().format();
        //console.log("updates",updates);
        rdb.ref().update(updates);
        localStorage.setItem('encomendaLastView', moment().format());
      }
    } else {
      var updates = {};
      updates["/curriculo/avaliacao/2021S2/profsCientes/"+self.userLog.id+"/logs/"+generatePushID()()] = moment().format();
      //console.log("updates",updates);
      rdb.ref().update(updates);
      localStorage.setItem('encomendaLastView', moment().format());
      console.log("local view não encontrado!",lastView);
    }

  },
  methods:{

    build() {
      var self = this;
    }
  }
}
</script>

<style scoped>
</style>
