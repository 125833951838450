<template>
  <div class="row" v-if="uc != null">
    <div class="col-12 col-lg-12 text-left">
      <p class="m-0 mb-0 f-monserrat fs-12pt fw-500">
        <i v-i:duo#graduation-cap#20 class="me-1"></i>
        <span class="fs-10pt fw-500">UC:</span>
        {{uc.nome}}
      </p>
      <hr class="my-0 mt-1">
      <!-- TABS -->
      <v-tabs class="mt-1" height="28" v-model="tab" background-color="transparent">
        <v-tab class="mt-0 px-2 me-1" v-for="item in ['Plano de ensino', 'Professores', 'Avaliação', 'Dual', 'Relatos de Experiências']" :key="item" style="min-width: 0px;">
          <template slot:default class="px-0" >
            <span class="text-nonecase f-raleway fs-11pt fw-600">{{ item }}</span>
          </template>
        </v-tab>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card outlined elevation="4" class="mt-2">
              <v-card-text>
                <planoview ref="planoviewref" v-if="boxview == 'plano'" :area="area" :uc="uc" @listaDescontinuados="listaDescontinuados" />
                <descontinuadosementa v-if="boxview == 'ementa'" :area="area" :ucSel="uc" @close="close(0)" />
                <descontinuadostopicos v-if="boxview == 'topicos'" :area="area" :ucSel="uc" @close="close(1)" />
                <descontinuadosmetas v-if="boxview == 'metas'" :area="area" :ucSel="uc" @close="close(2)" />
                <descontinuadoscompetencias v-if="boxview == 'competencias'" :area="area" :ucSel="uc" @close="close(3)" />
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item class="pb-0">
            <v-card outlined class="mt-2 pb-0 mb-0">
              <v-card-text class="pb-0 mb-0">
                <professoresuc :area="area" :ucSel="uc" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item>
            <v-card outlined class="mt-2">
              <v-card-text>
                <avaliacaoview :area="area" :uc="uc" />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item>
            <v-card outlined class="mt-2" style="height: 400px;">
              <v-card-text>
                <v-alert type="info" class="fs-10pt text-dark">
                  Funcionalidade em construção.
                </v-alert>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item>
            <v-card outlined class="mt-2" style="height: 400px;">
              <v-card-text>
                <v-alert type="info" class="fs-10pt text-dark">
                  Funcionalidade em construção.
                </v-alert>
              </v-card-text>
            </v-card>
          </v-tab-item>

        </v-tabs-items>
      </v-tabs>

    </div>
  </div>
</template>

<script>
import { rdb, snapshotValToArray, objToArray } from '@/firebasedb.js'
import dummy from "@/components/dummy/dummy.vue"
import planoview from "@/views/cursos/planos/planoView.vue"
import descontinuadosementa from "@/views/cursos/planos/descontinuadosEmenta.vue"
import descontinuadosmetas from "@/views/cursos/planos/descontinuadosMetas.vue"
import descontinuadostopicos from "@/views/cursos/planos/descontinuadosTopicos.vue"
import descontinuadoscompetencias from "@/views/cursos/planos/descontinuadosCompetencias.vue"
import avaliacaoview from "@/views/cursos/avaliacao/avaliacaoView.vue"
import professoresuc from "@/views/cursos/professores/professoresUC.vue"

export default {
  name: "planoContainer",
  components: { dummy, planoview, professoresuc, descontinuadosementa, descontinuadosmetas, descontinuadostopicos, descontinuadoscompetencias, avaliacaoview },
  props: [ "ucSel" ],
  computed: {
    isEditLock: function () {
      return this.editLock;
    }
  },
  data(){
    return {
      tab: null,
      boxview: "plano",
      uc: null,
    }
  },
  computed: {
    area() {
      return { cor: this.cor.active.I };
    }
  },
  watch: {
    ucSel(to, from) {
      var self = this;
      self.uc = self.ucSel;
      this.boxview = "plano";
      console.log("planoContainer change uc");
    }
  },
  mounted: function() {
    var self = this;
    self.uc = self.ucSel;
  },
  methods:{

    listaDescontinuados(tipo) {
      console.log("listaDescontinuados ",tipo);
      this.boxview = tipo;
    },

    close(tab) {
      var self = this;
      console.log(tab);
      this.boxview = 'plano';
      this.$nextTick(function() {
        self.$refs.planoviewref.goTab(tab);
      });
    }

  }
}
</script>

<style scoped>

</style>
