<template>
  <v-card class="p-1">
    <div class="my-1 mx-0 flexb falign-center" style="justify-content: space-between;">
      <div class="mx-auto mt-2">
        <svgreceipt size="w100 ms-5" />
      </div>
      <div class="flex1 ms-2">
        <v-card class="px-2 py-1 pt-4 mx-2 h100" :color="hexTints(cor.active.b,.6)" v-c:I flat >
          <p class="my-0 mb-n1 p-0 text-center mt-2 line-height-1">
            <span class="ms-1 fs-15pt f-roboto fw-600">AVALIAÇÃO A2 - 2021/2</span>
          </p>
          <p class="mt-0 mb-1 p-0 text-center mt-3 line-height-1 fs-9pt f-raleway fw-500">
            <span class="fs-9pt f-roboto fw-600">ENCOMENDA:</span>
            Elaboração de itens de avaliação
          </p>
        </v-card>
      </div>
    </div>

    <div class="my-1 mt-3 mx-0 flexb falign-center" style="justify-content: center;" v-if="true">
      <v-card class="w180 px-2 py-0 mx-2" :color="hexTints(cor.active.b,.8)" v-c:P outlined elevation="2">
        <p class="my-0 mb-n1 p-0 text-center mt-2 line-height-1 fs-9pt f-roboto fw-400">
          Unidades Curriculares
        </p>
        <p class="mt-0 p-0 mb-2 text-center mt-3 line-height-1 fs-11pt f-roboto fw-600">
          {{Object.keys(ucs).length}}
        </p>
      </v-card>
      <v-card class="w180 px-2 py-0 mx-2" :color="hexTints(cor.active.b,.8)" v-c:P outlined elevation="2">
        <p class="my-0 mb-n1 p-0 text-center mt-2 line-height-1 fs-9pt f-roboto fw-400">
          Metas de compreensão
        </p>
        <p class="mt-0 p-0 mb-2 text-center mt-3 line-height-1 fs-11pt f-roboto fw-600">
          {{Object.keys(metas).length}}
        </p>
      </v-card>
      <v-card class="w180 px-2 py-0 mx-2" :color="hexTints(cor.active.b,.8)" v-c:P outlined elevation="2">
        <p class="my-0 mb-n1 p-0 text-center mt-2 line-height-1 fs-9pt f-roboto fw-400">
          Itens de avaliação
        </p>
        <p class="mt-0 p-0 mb-2 text-center mt-3 line-height-1 fs-11pt f-roboto fw-600">
          {{Object.keys(encomendas).length}}
        </p>
      </v-card>
    </div>
    <hr class="my-1 mt-3"/>
    <v-expansion-panels class="px-3 pt-2 pb-0 mt-2" v-bg:b v-if="true">
      <v-expansion-panel class="mt-1 mb-3 p-0" v-for="(uc,key,index) in ucs" :key="key" v-bg:b>
        <v-expansion-panel-header class="m-0 ps-0 pe-2 py-0" :color="hexTints(cor.active.b,.5)">
          <ucitem :uc="uc" :encomendas="encomendas" :key="uc+refresh"/>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="p-0 mb-n4 ms-n6 me-n6">
          <encomendasdetalhes :uc="uc" :encomendas="encomendas" :key="uc+refresh"/>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-card class="p-1" v-if="false">
        <v-alert type="info" :color="cor.active.S">
          <p class="m-0 p-0 fs-10pt">Encomendas serão disponibilizadas em breve...</p>
        </v-alert>
    </v-card>


  </v-card>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import svgreceipt from "@/components/svgs/svg-receipt.vue";
import ucitem from "./uc-item.vue";
import encomendasdetalhes from "./encomenda-detalhes.vue";

export default {
  name: "cardencomendasa2",
  components: { svgreceipt, ucitem, encomendasdetalhes },
  props: [],
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      encomendas: {},
      ucs: {},
      metas: {},
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    self.loading = true;
    rdb.ref('/curriculo/avaliacao/2021S2/encomendasA2').orderByChild("profID").equalTo(this.userLog.id).on('value',function(snapshot) {
      self.encomendas = snapshot.val();
      console.log("self.encomendas",self.encomendas);
      self.ucs = {};
      self.metas = {};
      for(var key in self.encomendas) {
        self.ucs[self.encomendas[key].ucID] = self.encomendas[key].ucID;
        self.metas[self.encomendas[key].meta] = self.encomendas[key].meta;
      }
      self.refresh++;
      console.log("self.ucs",self.ucs);
      console.log("self.metas",self.metas);
    });

  },
  methods:{

    build() {
      var self = this;
    }
  }
}
</script>

<style scoped>
</style>
