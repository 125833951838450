
export const speech = {
  SpeechSDK: SpeechSDK,
  subscriptionKey: "4febecd5a5bb40f9a4f662d834814735",
  serviceRegion: "brazilsouth",
  player: null,
  audioConfig: null,
  speechSynthesisVoiceName: "pt-BR-FranciscaNeural",
  speechSynthesisLanguage: "pt-BR",
  speechConfig: null,
  synthesizer: null,
  genero: "f",
  words: null,
  visemeReceived: function (s, e) {
    window.console.log("(Viseme), Audio offset: " + e.audioOffset / 10000 + "ms. Viseme ID: " + e.visemeId);
  },
  init: function() {
    speech.player = new SpeechSDK.SpeakerAudioDestination();
    speech.audioConfig  = SpeechSDK.AudioConfig.fromSpeakerOutput(speech.player);
    speech.speechConfig = SpeechSDK.SpeechConfig.fromSubscription(speech.subscriptionKey, speech.serviceRegion);
    speech.speechConfig.speechSynthesisVoiceName = speech.speechSynthesisVoiceName;
    speech.speechConfig.speechSynthesisLanguage = speech.speechSynthesisLanguage;
    console.log("speechConfig initialized...",speech.speechConfig);
    speech.synthesizer = new SpeechSDK.SpeechSynthesizer(speech.speechConfig, speech.audioConfig);
    console.log("synthesizer initialized...",speech.synthesizer);
    speech.synthesizer.visemeReceived = speech.visemeReceived;
  },
  setGenero(genero) {
    speech.genero = genero.charAt(0).toLowerCase();
  },
  getdWord: function(name) {
    for(var i=0; i<speech.words.length;i++) {
      if(name == speech.words[i].tagName) {
        return speech.words[i];
      }
    }
    return {};
  },
  getWordSSML: function(item) {
    return speech.buildProsody(item.tagText,item);
  },
  parseGenero: function(texto) {
    var artigos = [
      { token: /~o/g, m: "o", f: "a" },
    ];
    var ret = texto;
    for(var i=0; i<artigos.length; i++) {
      ret = ret.replace(artigos[i].token,artigos[i][speech.genero]);
    }
    return ret;
  },
  buildProsody: function(innerContent,attr) {
    var texto = ""
    if(attr.pitch != undefined && attr.pitch != "default") {
      texto = texto + ' pitch="' + attr.pitch + '"';
    }
    if(attr.rate != undefined && attr.rate != "default") {
      texto = texto + ' rate="' + attr.rate + '"';
    }
    if(attr.volume != undefined && attr.volume != "default") {
      texto = texto + ' volume="' + attr.volume + '"';
    }
    innerContent = speech.parseGenero(innerContent);
    if(texto != "") {
      texto = '<prosody' + texto + '>\n' + innerContent + '\n</prosody>'
    } else {
      texto = innerContent;
    }
    if(attr.style != undefined && attr.style != "normal") {
      texto = '<mstts:express-as style="calm">\n' + texto + '\n</mstts:express-as>';
    }
    return texto;
  },
  buildSSML: function(innerContent) {
    var texto = '<speak version="1.0" xmlns="https://www.w3.org/2001/10/synthesis" xmlns:mstts="http://www.w3.org/2001/mstts" xmlns:emo="http://www.w3.org/2009/10/emotionml" xml:lang="pt-BR">';
    texto = texto + '<voice name="'+speech.speechSynthesisVoiceName+'">';
    texto = texto + innerContent + '</voice></speak>';
    return texto;
  },
  play: function(text, cbstart = () => {}, cbfinish = () => {} ) {
    speech.init();
    var ssml = speech.buildSSML(text);
    console.log(ssml);
    speech.player.onAudioStart = function () {
      console.log("AUDIO START!!!!!!!!");
      cbstart();
    };
    speech.player.onAudioEnd = function () {
      console.log("AUDIO FINISH!!!!!!!!");
      cbfinish();
    };
    speech.synthesizer.speakSsmlAsync(ssml, function (result,stream) {
      if (result.reason === SpeechSDK.ResultReason.SynthesizingAudioCompleted) {
        console.log("synthesis finished successfully!!!!");
      } else if (result.reason === SpeechSDK.ResultReason.Canceled) {
        console.log("synthesis failed. Error detail: " + result.errorDetails);
      }
      console.log(result);
      speech.synthesizer.close();
      speech.synthesizer = undefined;
    },
    function (err) {
      console.log("Error: ",err);
      speech.synthesizer.close();
      speech.synthesizer = undefined;
    });
  }
}
